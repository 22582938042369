import React from "react";
import "../styles/App.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Button } from "../components/Button";
import packing from "../images/packing.jpg";

function PackingService() {
  return (
    <>
      <NavBar
        title="Packing Service | Point Blank Moving"
        description="Lee's Summit Movers, the best local moving company in Lee Summit. Point Blank Moving is Kansas City's premier moving company! Proud to be servicing Lee's Summit Missouri. The best packing service company in Kansas City Missouri."
      />
      <div
        className="landing-container"
        style={{
          backgroundImage: `url(${packing})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <h1 style={{ textShadow: "0 0 5px black, 0 0 5px black" }}>
          Kansas City Packing Services
        </h1>
        <div className="landing-btns">
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
            path="/Quote"
          >
            Check Availability
          </Button>
          <Button
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--large"
            path="/Contact"
          >
            GET IN CONTACT
          </Button>
        </div>
      </div>
      <div>
        <div className="content">
          <div className="content--loc--header">
            <h2
              style={{
                fontSize: "30px",
                marginTop: 20,
                marginBottom: 10,
                padding: 10,
                textAlign: "center",
              }}
            >
              Get the Best Kansas City Packing Services with Packing Point Blank
            </h2>
            <div>
              Get the best Kansas City Packing services with Packing Point
              Blank. In addition to packing your home, we’re happy to bring you
              your boxes, packing paper, bubble wrap, tape, markers and other
              material packing needs you have. We can also adjust your service
              level when it comes to packing. For example we can pack only the
              kitchen, basement or whatever else you may be dreading dealing
              with alone.
            </div>
          </div>
          <div className="content--loc--generic">
            <h2 style={{ fontSize: "25px", marginTop: 20, marginBottom: 10 }}>
              Prepare To Do Absolutely Nothing
            </h2>
            <div>
              Point Blank packers are trained professionals. Our team members
              save you time, stress, and damage by getting your items packed
              quickly and efficiently while using the safest, most efficient
              packing methods. If you're tired of tedious late-night packing
              sessions (it is never fun, we understand), Point Blank Moving’s
              professional packing is your solution! Get your free packing quote
              today!
              <br />
              <br />
              If you're packing alone, pack like a pro with this professional
              training video!
              <iframe
                style={{ marginTop: 30, marginBottom: 30 }}
                width="560"
                height="315"
                src="https://www.youtube.com/embed/g-D0PE2XotQ"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="content--loc--generic">
              <h3 style={{ fontSize: "23px", marginTop: 20, marginBottom: 10 }}>
                You Need Experienced Local Movers – Hire Us
              </h3>
              <div>
                At Point Blank Moving, we are a veteran led team with 20+ years
                of logistics experience covering all areas of Kansas City -
                Missouri and/or Kansas.
              </div>
            </div>
            <div className="content--loc--generic">
              <h3 style={{ fontSize: "23px", marginTop: 20, marginBottom: 10 }}>
                Point Blank Packing Service
              </h3>
              <div>
                Our professional packers will take your move from stress free to
                hands free! Our team will pack your belongings safely and get
                the job done in record time while you do the more important
                things.
              </div>
            </div>
            <div className="content--loc--generic">
              <h3 style={{ fontSize: "23px", marginTop: 20, marginBottom: 10 }}>
                Point Blank Unpacking Service
              </h3>
              <div>
                Not a fan of unpacking as well? We don’t blame you, let our
                professional packers help unpack your items and organize each
                room for you! Want your closet color-coded? No problem. We are
                here to serve you so you can relax and enjoy your new space!
              </div>
            </div>
            <div className="content--loc--generic">
              <h3 style={{ fontSize: "23px", marginTop: 20, marginBottom: 10 }}>
                Point Blank Parcial Packing-Unpacking Service
              </h3>
              <div>
                Maybe you don’t need everything packed or unpacked, just the
                things you really don’t want to deal with like the kitchen or
                basement living space. We can customize the packing service
                based on what you do or don’t want to do yourself. Whether you
                just need our packers to pack the kitchen or a closet - you
                decide when the clock stops.
              </div>
            </div>
            <div className="content--loc--generic">
              <h2 style={{ fontSize: "25px", marginTop: 20, marginBottom: 10 }}>
                Hiring Professional Packing Services in Kansas City
              </h2>
              <div>
                Our team of experts will ensure that all your items are properly
                packed and safely transported to your new home. By hiring Point
                Blank Moving for your move to Kansas City or one of it’s
                beautiful suburbs, you can rest assured that you won’t have to
                deal with any part of the stressful and time-consuming process.
                Sit back, relax, and let our team of experts take care of the
                hard part.
                <br />
                <br />
                <strong>
                  Hire the Point Blank Moving team today! Call now for a free,
                  no-obligation moving quote.
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PackingService;
